/**
 *
 * TODO: ADD JEST HERE
 *
 * getHandle('twitter.com/bla')
 * // '@bla'
 * getHandle('twitter.com/bla/')
 * // '@bla'
 * getHandle('https://twitter.com/bla/')
 * // '@bla'
 */

const getHandle = url => {
  const last = url[url.length - 1]
  if (last !== '/') return '@' + url.slice(url.lastIndexOf('/') + 1)
  return '@' + url.split('/')[url.split('/').length - 2]
}

export default getHandle
