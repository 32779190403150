import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import MetaHeader from '../components/MetaHeader'

import Layout from '../layouts'
import { vars } from '../components/theme'
import PageWrapper from '../components/PageWrapper'
import PersonBlock from '../components/PersonBlock'

const TeamContainer = styled.div`
  display: grid;
  grid-template: repeat(2, auto) / 1fr;
  grid-gap: 1rem 1.5rem;

  @media (min-width: ${vars.screenSmall}) {
    grid-template: repeat(2, auto) / repeat(2, 1fr);
  }
`
const Wrapper = styled.div`
  margin: 4rem 0;
`
const Team = ({ data }) => (
  <Layout>
    <MetaHeader title="Team" />

    <Wrapper>
      <PageWrapper>
        <h1>Team</h1>

        <TeamContainer>
          {data.allContentfulTeam.edges
            .sort((a, b) => (a.node.order || 9999) - (b.node.order || 9999))
            .map(({ node: person }) => (
              <PersonBlock
                key={person.image.id}
                name={person.name}
                img={person.image.file.url}
                title={person.title}
                linkedin={person.linkedin}
                twitter={person.twitter}
              />
            ))}
        </TeamContainer>
      </PageWrapper>
    </Wrapper>
  </Layout>
)

export default Team

export const query = graphql`
  query Team {
    allContentfulTeam {
      edges {
        node {
          order
          name
          image {
            id
            file {
              url
            }
          }
          title
          linkedin
          twitter
        }
      }
    }
  }
`
