import React from 'react'
import styled from 'styled-components'

import { colors, vars } from './theme'
import Img from './Img'
import { LinkedIn, Twitter } from './Icons'
import Link from './common/Link'
import getHandle from '../utils/getHandle'

const Wrapper = styled.div`
  display: grid;
  grid-template: 1fr / 33% 67%;
  grid-gap: 1rem;

  @media (min-width: ${vars.screenSmall}) {
    grid-template: 1fr / 33% 67%;
  }
`

const ProfilePictureContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 100%;
  margin-top: 0.25rem;
`

const ProfilePicture = styled(Img)`
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
  filter: saturate(0.5);
  border-radius: 3px;
`

const Name = styled.div`
  font-size: 1.25rem;
`

const Title = styled.div`
  font-size: 1rem;
  margin: 0.25em 0 0;
  color: ${colors.grey};

  &:after {
    content: '';
    display: block;
    margin: 0.25em 0 0;
    width: 5rem;
    height: 0.25rem;
    background: ${colors.borderLight};
  }
`

const BioContainer = styled.div`
  flex: 0 0 67%;
  max-width: 450px;
  margin-top: 1rem;

  @media (min-width: ${vars.screenSmall}) {
    margin-top: 0;
  }
`

const SocialContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 0.5rem;
  font-size: 0.875rem;
`

const ProfileLink = styled(Link)`
  margin: 0.125rem 0;
  height: 1.125rem;
  display: flex;
  align-items: center;
  text-decoration: none;

  > .icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
`

const LinkedInLink = styled(ProfileLink)`
  color: ${colors.linkedIn};

  &:visited {
    color: ${colors.linkedIn};
  }
`
const TwitterLink = styled(ProfileLink)`
  color: ${colors.twitter};

  &:visited {
    color: ${colors.twitter};
  }
`

const PersonBlock = ({
  className,
  img = null,
  name,
  title,
  linkedin,
  twitter,
}) => (
  <Wrapper>
    <ProfilePictureContainer>
      <ProfilePicture src={img} alt={name} />
    </ProfilePictureContainer>
    <BioContainer>
      <Name>{name}</Name>
      <Title>{title}</Title>
      <SocialContainer>
        {!!linkedin && (
          <LinkedInLink href={linkedin}>
            <LinkedIn className="icon" color /> {getHandle(linkedin)}
          </LinkedInLink>
        )}
        {!!twitter && (
          <TwitterLink href={twitter}>
            <Twitter className="icon" color /> {getHandle(twitter)}
          </TwitterLink>
        )}
      </SocialContainer>
    </BioContainer>
  </Wrapper>
)

export default PersonBlock
